import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { navigate } from "gatsby";
import styled from "styled-components";
import * as Ant from "antd";

import LoginForm from "../Components/LoginForm";
import Widget, { Font } from "../Components/Widget";
import SurveyList from "../Components/SurveyList";
import TemplatesPage from "../Components/MyTemplates/TemplatesPage";
import * as SvgIcon from "../Components/SvgIcon";

import ActionCreator from "../ActionCreator";
import Selectors from "../Selectors";
import { withPage } from "../PageContainer";
import * as Survey from "../Contexts/SurveyContext";
import { Color } from "../Components/Widget";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { SettingsOverscan } from "styled-icons/material-twotone";

const queryString = require("query-string");
let windowGlobal = typeof window !== "undefined" && window;

const navbarHeight = 64;

/**
 * 建立問卷 modal
 * @param {visible} bool 是否呈現
 * @param {profile} obj 團隊代碼
 * @param {onCancel} func 取消 callback
 * @param {survey} obj 問卷物件
 * @return {EditMemberModal}
 * @returns
 */
const CreateSurveyModal = ({
  visible,
  onCancel,
  survey,
  profile,
  ...props
}) => {
  const [spin1, setSpin1] = useState(false);
  const [spin2] = useState(false);
  const [showImportTemplateModal, setShowImportTemplateModal] = useState(false);
  const [templateList, setTemplateList] = useState([]);

  /**
   * 建立空白問券
   * @param {*} profile User info
   */
  async function createBlankSurvey(profile) {
    try {
      setSpin1(true);
      const _survey = await Survey.Actions.createSurvey(profile, survey, false);
      navigate(`/survey?id=${_survey.id}`);
      onCancel();
    } catch (ex) {
      setSpin1(false);
    }
  }

  useEffect(() => {
    async function fetchTemplateList() {
      try {
        let list = await Survey.Actions.fetchTemplateList();
        list.data && setTemplateList(list.data);
      } catch (err) {
        setTemplateList([]);
      }
    }

    if (showImportTemplateModal) {
      fetchTemplateList();
    }
  }, [showImportTemplateModal]);

  return (
    <>
      <Ant.Modal
        title="建立新問卷"
        visible={visible}
        footer={null}
        onCancel={onCancel}
      >
        <Widget.FlexRow style={{ justifyContent: "center" }}>
          <Widget.FlexCenter>
            <Widget.FlexCenter
              style={{
                width: 200,
                height: 200,
                border: "1px solid #ccc",
                borderRadius: 10,
                marginBottom: 12,
              }}
              onClick={() => createBlankSurvey(profile)}
            >
              {!spin1 ? (
                <CrossWrapper>
                  <div className="cross-x" />
                  <div className="cross-y" />
                </CrossWrapper>
              ) : (
                <div
                  style={{
                    height: "100vh",
                    width: "100vw",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "fixed",
                    zIndex: "999999",
                  }}
                >
                  <Ant.Spin />
                </div>
              )}
            </Widget.FlexCenter>
            <Font.Body>空白問卷</Font.Body>
          </Widget.FlexCenter>
          <div style={{ width: 20 }} />
          <Widget.FlexCenter>
            <Widget.FlexCenter
              style={{
                width: 200,
                height: 200,
                border: "1px solid #ccc",
                borderRadius: 10,
                marginBottom: 12,
              }}
              onClick={() => setShowImportTemplateModal(true)}
            >
              {!spin2 ? (
                <CrossWrapper>
                  <div className="cross-x" />
                  <div className="cross-y" />
                </CrossWrapper>
              ) : (
                <div
                  style={{
                    height: "100vh",
                    width: "100vw",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "fixed",
                    zIndex: "999999",
                  }}
                >
                  <Ant.Spin />
                </div>
              )}
            </Widget.FlexCenter>
            <Font.Body>我的範本</Font.Body>
          </Widget.FlexCenter>
        </Widget.FlexRow>
      </Ant.Modal>
      <Ant.Modal
        visible={showImportTemplateModal}
        width={1152}
        footer={null}
        onCancel={() => setShowImportTemplateModal(false)}
        closable={false}
        bodyStyle={{ padding: 0 }}
        centered
        style={{ paddingBottom: 0, minWidth: "1152px" }}
        destroyOnClose
        wrapClassName="modal-wrapper"
      >
        <ImportTemplateModalWrapper>
          <div
            className="close"
            onClick={() => setShowImportTemplateModal(false)}
          >
            <SvgIcon.Close></SvgIcon.Close>
          </div>
          <div className="zoom-out" onClick={() => navigate("/templates")}>
            <SvgIcon.openInFullFill></SvgIcon.openInFullFill>
          </div>
          <TemplatesPage
            popup={true}
            profile={profile}
            fromSurvey={survey}
            {...props}
          />
        </ImportTemplateModalWrapper>
      </Ant.Modal>
    </>
  );
};

const ImportTemplateModalWrapper = styled.div`
  .close {
    position: absolute;
    top: -15px;
    right: -15px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background-color: #e0e0e0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .zoom-out {
    position: absolute;
    top: 47px;
    right: 30px;
    cursor: pointer;
    width: 24px;
    height: 24px;
  }

  .template-wrapper {
    height: 90vh;
  }
`;

/**
 * 問券列表主要內容
 * @param {profile} obj 用戶資料物件
 * @returns
 */
function SurveysPage({ profile, ...props }) {
  const [loading, setLoading] = useState(false);
  const [surveys, setSurveys] = useState([]);
  const [selectSurvey, setSelectSurvey] = useState(null);
  const [surveyStatusList, setSurveyStatusList] = useState([]);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [updater, setUpdater] = useState(0);
  const [pageTitle, setPageTitle] = useState("");

  const [originCategory, setOriginCategory] = useState(null);
  const [localToken, setLocalToken] = useState(() => {
    windowGlobal = typeof window !== "undefined" && window;
    const stickyValue =
      windowGlobal && windowGlobal.localStorage.getItem("token");
    return stickyValue !== null ? stickyValue : profile && profile.token;
  });

  const [filters, setFilters] = useState({
    userName: [],
  });
  const [search, setSearch] = useState(null);
  const [pageInfo, setPageInfo] = useState({ current: 1, total: 0 });
  const [queryInfo, setQueryInfo] = useState({});
  const [page, setPage] = useState(1);
  const [reset, setReset] = useState(false);
  const perPage = 10;

  windowGlobal = typeof window !== "undefined" && window;
  let storetoken =
    windowGlobal && windowGlobal.localStorage.getItem("token")
      ? windowGlobal.localStorage.getItem("token")
      : profile && profile.token;
  let localprofile =
    windowGlobal && windowGlobal.localStorage.getItem("profile")
      ? windowGlobal.localStorage.getItem("profile")
      : profile && profile;
  const { appActions } = props;
  // 解析 query string 包含 uid / category
  let uid =
    queryString.parse(props.location.search, { decode: false }).uid ===
    undefined
      ? ""
      : queryString.parse(props.location.search, { decode: false }).uid;
  const [ssoUid, setSsoUid] = useState(null);

  let category =
    queryString.parse(props.location.search, { decode: false }).category ===
    undefined
      ? ""
      : queryString.parse(props.location.search, { decode: false }).category;

  useEffect(() => {
    setReset(false);
  }, [reset]);

  // 分類往內傳，會導致切換問卷時不會重設，這邊塞一個 origin 變數去比對是否有變動
  // 若有變動就重設
  if (originCategory !== category) {
    setPage(1);
    setQueryInfo({});
    setSearch(null);
    setOriginCategory(category);
    setReset(true);
  }
  useEffect(() => {
    windowGlobal = typeof window !== "undefined" && window;
    storetoken =
      windowGlobal && windowGlobal.localStorage.getItem("token")
        ? windowGlobal.localStorage.getItem("token")
        : profile && profile.token;
    localprofile =
      windowGlobal && windowGlobal.localStorage.getItem("profile")
        ? windowGlobal.localStorage.getItem("profile")
        : profile && profile;
    storetoken && setLocalToken(storetoken);

    let localexpired = windowGlobal.localStorage.getItem("expired");
    if (localexpired) {
      Ant.Modal.warning({
        title: "提醒",
        content: "您使用的是無效Token，系統將自動為您登出。",
        okText: "確定",
        onOk: () => {
          window.localStorage.removeItem("expired");
        },
        icon: <ExclamationCircleFilled color={Color.LightGold} size={20} />,
      });
    }

    /**
     * 取得問券列表
     */
    async function fetchData() {
      setLoading(true);

      if (category === "my") {
        setPageTitle("我的問卷");
      } else if (category === "shared") {
        setPageTitle("與我共享的問卷");
      } else {
        setPageTitle("所有問卷");
      }

      try {
        // 拉問卷清單，包含我的、分享、所有
        if (category === "my") {
          console.log(profile, page, perPage, queryInfo, search);
          const surveyData = await Survey.Actions.fetchMyOwnSurveys(
            profile,
            page,
            perPage,
            queryInfo,
            search
          );
          setSurveys(surveyData.data);
          setFilters(surveyData.filters);
          setPageInfo(surveyData.pagination);
        } else if (category === "shared") {
          const surveyData = await Survey.Actions.fetchSharedSurveys(
            profile,
            page,
            perPage,
            queryInfo,
            search
          );
          setSurveys(surveyData.data);
          setFilters(surveyData.filters);
          setPageInfo(surveyData.pagination);
        } else {
          const surveyData = await Survey.Actions.fetchMySurveys(
            profile,
            page,
            perPage,
            queryInfo,
            search
          );
          setSurveys(surveyData.data);
          setFilters(surveyData.filters);
          setPageInfo(surveyData.pagination);
        }
        setSurveyStatusList(await Survey.Actions.fetchSurveyStatus());
      } catch (ex) {
        // alert("系統異常，請洽系統管理員");
        throw ex;
      }
      setLoading(false);
    }
    if (localprofile && storetoken) {
      fetchData();
    }
  }, [profile, updater, ssoUid, category, page, queryInfo, search]);

  //判斷如為sso則不轉導登入而是直接取得token&userinfo
  //crmsurvey.cht.com.tw 內網
  //ecrmsurvey.cht.com.tw 外網
  // console.log('props.location===>',props.location);

  useEffect(() => {
    windowGlobal = typeof window !== "undefined" && window;
    storetoken =
      windowGlobal && windowGlobal.localStorage.getItem("token")
        ? windowGlobal.localStorage.getItem("token")
        : profile && profile.token;
    localprofile =
      windowGlobal && windowGlobal.localStorage.getItem("profile")
        ? windowGlobal.localStorage.getItem("profile")
        : profile && profile;

    storetoken && setLocalToken(storetoken);
    uid =
      queryString.parse(props.location.search, { decode: false }).uid ===
      undefined
        ? ""
        : queryString.parse(props.location.search, { decode: false }).uid;
    let hostname = props.location.host;
    // check host url to set the token Valid by state.
    //判斷是否在可運行的網址環境並確認token狀態
    if (
      (hostname === "localhost:8000" || // 本地端環境
      hostname === "localhost:9000" || // 本地端環境
      hostname === "139.162.67.143:8080" || // 測試網址環境
      hostname === "ecrmsurvey.cht.com.tw" || // 中華正式環境
        hostname === "crmsurvey.cht.com.tw") && // 中華正式環境
      uid !== "" && // uid 不得為空
      ssoUid !== uid // ssouid 與 uid 不得相同
    ) {
      try {
        appActions.ssoLogin(uid);
        setSsoUid(uid);
        setUpdater(updater + 1);
      } catch (err) {}
    } else {
      // console.log("ssoUid", ssoUid);
      // console.log("sso bypass | ", hostname);
    }
  }, [uid, profile]);

  useEffect(() => {
    windowGlobal = typeof window !== "undefined" && window;
    let tokenck = windowGlobal && windowGlobal.localStorage.getItem("token");
    let profileck =
      windowGlobal && windowGlobal.localStorage.getItem("profile");

    if (profile && !tokenck && !profileck) {
      // appActions.logout();
    } else if (tokenck) {
      Survey.Actions.setInternalLocalStorageToken(true);
    }
  }, [profile, updater]);

  // 登入畫面
  if (!storetoken || !localprofile) {
    return (
      <LoginPage>
        <div className="LoginForm-Wrapper">
          <LoginForm />
        </div>
      </LoginPage>
    );
  }

  return (
    <Wrapper>
      <div className="content">
        {/* 將分頁資料傳入 SurveyList 元件中 */}
        <SurveyList
          surveyStatusList={surveyStatusList}
          surveys={surveys}
          filters={filters}
          loading={loading}
          openShowCreateModal={survey => {
            setShowCreateModal(true);
            setSelectSurvey(survey);
          }}
          pageTitle={pageTitle}
          profile={profile}
          pageInfo={pageInfo}
          perPage={perPage}
          onPageChange={page => {
            setPage(page);
          }}
          onTableChange={(selectedRowKeys, selectedRows, info) => {
            setPage(1);
            setQueryInfo(selectedRows);
          }}
          onSearch={keyword => {
            setPage(1);
            setSearch(keyword);
          }}
          reset={reset}
        />
        <CreateSurveyModal
          visible={showCreateModal}
          survey={selectSurvey}
          surveyTwo={selectSurvey}
          onCancel={() => {
            setShowCreateModal(false);
            setSelectSurvey(null);
          }}
          profile={profile}
        />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #fafafa;
  min-height: calc(100vh - 64px);

  & > .content {
    width: 1440px;
    height: calc(100vh - ${navbarHeight}px);
    padding: 40px 36px 34px;
    overflow: hidden scroll;

    @media screen and (min-width: 1440px) {
      width: 100%;
    }
  }
`;

const LoginPage = styled.div`
  width: 100%;
  height: 100%;

  & > .LoginForm-Wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  }
`;

const CrossWrapper = styled.div`
  position: relative;
  width: 54px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  & .cross-x {
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #979797;
  }

  & .cross-y {
    position: absolute;
    height: 100%;
    width: 1px;
    background-color: #979797;
  }
`;

export default withPage(
  connect(
    (state, ownProps) => ({
      profile: Selectors.getLoginUser(state),
    }),
    ActionCreator
  )(SurveysPage)
);
